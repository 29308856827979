import React from "react";
import { WidgetLayoutWithSettings } from "./openai-widget/layout";
import WidgetChat from "./openai-widget";

import "./index.css";

export const App = ({ widgetId }: { widgetId: string }) => {
  const isSharedPrototype = window.location.href.includes("widget-prototype");
  return (
    <WidgetLayoutWithSettings widgetId={widgetId} defaultOpen={isSharedPrototype}>
      <WidgetChat />
    </WidgetLayoutWithSettings>
  );
};
