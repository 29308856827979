import { Timestamp } from "firebase/firestore";
import { IChatParticipant, Message } from "./types";

export const mockResponse = "Please add your assistant Parameters and save changes to test.";

export const errorMessageEn: Message = {
  role: "assistant",
  content: "Something is wrong. You can repeat the request.",
  createdAt: Timestamp.now(),
};

export const startMessageEn: Message = {
  role: "assistant",
  content: "Welcome - how can I help you?",
  createdAt: Timestamp.now(),
};

export const botPatricipantEn: IChatParticipant = {
  avatar: "/robot_icon_white_bg.png",
  id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
  name: "Hugo Pfohe Kaufberater",
  role: "AI",
  username: "Hugo Pfohe Kaufberater",
};

export const mockMessages: Message[] = [
  {
    content: "Hi!",
    createdAt: Timestamp.now(),
    role: "user",
  },
  {
    content: "Welcome! How can I assist you today?",
    createdAt: Timestamp.now(),
    role: "assistant",
  },
];
