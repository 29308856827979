import React from "react";

import { useEffect, type FC } from "react";
import { useWidgetSettingsContext } from "../settings";
import { useBoolean } from "../hooks/use-boolean";
import { getWidgetById } from "../lib/firestore";
import { Stack } from "@mui/material";
import { LoadingScreen } from "../components/loading-screen";

export const withSetWidgetSettings = <P extends object>(
  Component: FC<P>,
  widgetId: string,
  isHome?: boolean
): FC<P> => {
  return function SetWidgetSettings(props: P) {
    const widgetSettings = useWidgetSettingsContext();

    const applySettings = useBoolean(false);

    useEffect(() => {
      getWidgetById(widgetId).then((widget) => {
        if (widget) {
          widgetSettings.setSettings("assistant", widget.openai?.assistantId || "");
          widgetSettings.setSettings("name", widget.name);
          widgetSettings.setSettings("assistantAvatar", {
            preview: widget.customizations.assistantAvatar,
          } as any);
          widgetSettings.setSettings("launcherIcon", {
            preview: widget.customizations.launcherIcon,
          } as any);
          widgetSettings.setSettings("bottomSpacing", widget.position.y);
          widgetSettings.setSettings("sideSpacing", widget.position.x);
          widgetSettings.setSettings("chatPersistence", widget.persistChat ? "tabClosed" : "never");
          widgetSettings.setSettings("description", widget.description);
          widgetSettings.setSettings("inputPlaceholder", widget.inputPlaceholder);
          widgetSettings.setSettings("laucherBgColor", widget.customizations.laucherBgColor);
          widgetSettings.setSettings("mainColor", widget.customizations.mainColor);
          widgetSettings.setSettings("position", widget.position.isRight ? "right" : "left");

          widgetSettings.setSettings("startMessage", widget.startMessage);
          widgetSettings.setSettings("poweredBy", widget.poweredBy);
          widgetSettings.setSettings("widgetId", widgetId);
          
          widgetSettings.setSettings("teamId", widget.teamId);
          widgetSettings.setSettings("aiResponseText", widget.aiResponseText);
          widgetSettings.setSettings("uniqueSellingProposition", widget.uniqueSellingProposition);
          widgetSettings.setSettings("productInformation", widget.productInformation);
          widgetSettings.setSettings("informationToCapture", widget.informationToCapture);
          widgetSettings.setSettings("userActions", widget.userActions);
          widgetSettings.setSettings("userApiKey", widget.userApiKey);


          applySettings.onTrue();
        }
      });
    }, [widgetId]);

    if (isHome && (!widgetId || !applySettings.value)) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <LoadingScreen />
        </Stack>
      );
    }

    if (!widgetId || !applySettings.value) {
      return null;
    }

    return <Component {...props} />;
  };
};
