import React from "react";

import { useContext, useEffect, useRef } from "react";
// @types
//
import ChatMessageItem from "./ChatMessageItem";
import { ChatContext } from "../context";
import { Avatar, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useWidgetSettingsContext } from "../settings";
import Scrollbar from "../components/scrollbar";
import { mockResponse } from "../mockData";

// ----------------------------------------------------------------------

export default function ChatMessageList() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const settings = useWidgetSettingsContext();
  const { messages, status, errorMessage, editMode } = useContext(ChatContext);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    };
    scrollMessagesToBottom();
  }, [messages, status]);

  return (
    <Scrollbar
      scrollableNodeProps={{
        ref: scrollRef,
      }}
      sx={{ p: 2, height: 1 }}
    >
      <Stack
        alignItems="center"
        sx={{
          mb: 4,
        }}
      >
        <Avatar
          alt={settings.name}
          src={(settings.assistantAvatar as any)?.preview}
          sx={{ width: 80, height: 80 }}
        />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            mt: 1,
          }}
        >
          {settings.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textAlign="center"
          sx={{
            mt: 0.5,
          }}
        >
          {settings.description}
        </Typography>
      </Stack>
      {(messages.length === 0 ||
        messages[messages.length - 1].content === mockResponse ||
        !editMode) && (
        <ChatMessageItem
          message={{
            content: settings.startMessage,
            role: "assistant",
            createdAt: Timestamp.now(),
          }}
        />
      )}

      {messages.map((message, index) => (
        <ChatMessageItem key={index} message={message} />
      ))}

      {status === "typing" && messages[messages.length - 1]?.role === "user" && (
        <ChatMessageItem
          loadingMessage
          message={{
            role: "assistant",
            content: "Typing...",
            createdAt: Timestamp.now(),
          }}
        />
      )}
      {status === "error"
        // && messages[messages.length - 1]?.role != "assistant"
        && (
        <ChatMessageItem
          error
          message={{
            role: "assistant",
            content: errorMessage,
            createdAt: Timestamp.now(),
          }}
        />
      )}
    </Scrollbar>
  );
}
