import React from "react";

import { useContext, useState } from "react";
// @mui
import { InputBase, IconButton } from "@mui/material";
// components
import { ChatContext } from "../context";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";

// ----------------------------------------------------------------------

export default function ChatMessageInput() {
  const [message, setMessage] = useState("");
  const { status, sendMessage } = useContext(ChatContext);
  const settings = useWidgetSettingsContext();

  const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      if (message) {
        if (
          settings.aiResponseText !== "" &&
          settings.uniqueSellingProposition !== "" &&
          settings.productInformation !== ""
        ) {
          sendMessage(message);
        } else {
          settings.setSettings("errorEmptyAiParameters", true);
        }
      }
      setMessage("");
    }
  };

  const handleClickSend = () => {
    if (message) {
      if (
        settings.aiResponseText !== "" &&
        settings.uniqueSellingProposition !== "" &&
        settings.productInformation !== ""
      ) {
        sendMessage(message);
      } else {
        settings.setSettings("errorEmptyAiParameters", true);
      }
    }
    setMessage("");
  };

  return (
    <InputBase
      value={message}
      disabled={status === "typing"}
      onKeyUp={handleSend}
      onChange={(event) => setMessage(event.target.value)}
      placeholder={settings.inputPlaceholder}
      endAdornment={
        <IconButton disabled={status === "typing"} size="small" onClick={handleClickSend}>
          <Iconify icon="ic:baseline-send" />
        </IconButton>
      }
      sx={{
        px: 2,
        height: 56,
        flexShrink: 0,
        borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        "& input::placeholder": {
          fontWeight: 500,
        },
      }}
    />
  );
}
