"use client";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Timestamp,
  doc,
  setDoc,
  FieldValue,
  arrayUnion,
  updateDoc,
  getDoc
} from "firebase/firestore";
import { BotStatus, ChatDoc, Message } from "./types";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useWidgetSettingsContext } from "./settings";
import { clearAllConversation, createChatDoc, getMessage,updateChatDoc ,updateLeadInfo} from "./lib/firestore";
import { useMediaQuery, useTheme } from "@mui/material";
import { getSessionStorage, setSessionStorage } from "./hooks/use-session-storage";
import { mockResponse } from "./mockData";
import { addLead } from "./lib/firebase";


interface ChatContextType {
  messages: Message[];
  errorMessage: string;
  sendMessage: (userMessage: string) => void;
  clearConversation: () => void;
  status: BotStatus;
  setStatus: React.Dispatch<React.SetStateAction<BotStatus>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  isHome?: boolean;
}

export const ChatContext = React.createContext<ChatContextType>({
  clearConversation: () => {},
  messages: [],
  sendMessage: () => {},
  status: "online",
  setStatus: () => { },
  editMode:false,
  chatOpen: false,
  setChatOpen: () => {},
  errorMessage: "",
});
                                                                                                                 
interface ChatContextProviderProps {
  children: React.ReactNode;
  editMode?: boolean;
  widgetId?: string;
  defaultOpen?: boolean;
  isHome?: boolean;
}

// ----------------------------------------------------------------------
type FormValuesProps = {
  email: string;
  name: string;
  phone: string;
};
export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
  editMode,
  widgetId,
  defaultOpen,
  isHome,
}) => {
  const [messages, setMessages] = useState<ChatDoc["messages"]>([]);
  const [status, setStatus] = useState<BotStatus>("online");
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [chatOpen, setChatOpen] = useState<boolean>((!!editMode || !!defaultOpen) && isSmUp);
  const DB = useFirestore();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userInfo, setUserInfo] = useState<FormValuesProps>({ name: "", email: "", phone: "" })
  const [QuestionIndex, setQuestionIndex] = useState<number>(
    getSessionStorage(`chat-question-index-${widgetId}`) || 0
  );

  console.log("QuestonIndex", QuestionIndex);

  function isValidPhoneNumber(inputString: string) {
    const regex =
      /^(?:\+?1)?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$|^$/;
    if (!regex.test(inputString)) {
      setErrorMessage("Please enter phone number in valid format!");
    } else {
      return true;
    }
  }

  function isValidEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      console.log("error");
      setErrorMessage("Please enter email in valid format!");
    } else {
      return true;
    }
  }

  const questionsMap = {
    name: "To better assist you, may I have your name, please?",
    email: "What's the best email address to send some more info?",
    phone: "What’s the best phone number to reach you?",
  };
  const widgetSettings = useWidgetSettingsContext();

  const isPaid = true;

  const chatRef = doc(
    DB,
    "widgets",
    widgetId || "0",
    "chats",
    getSessionStorage(`user-widget-id-${widgetId}`) || "0"
  );

  const [chatId, setChatId] = useState<string>(
    getSessionStorage(`user-widget-id-${widgetId}`)
  );

  const { data, status: dataStatus } = useFirestoreDocData(chatRef);
  console.log("data", data?.messages?.length);
  console.log("mesage leng", messages.length);


    function addNameQuestion(chats: ChatDoc["messages"]) {
    let assistantObj = chats.find(msg => msg.role === "assistant");
    console.log("asObj", assistantObj)
    if (assistantObj && !assistantObj.content?.includes(questionsMap.name)
      && widgetSettings.informationToCapture?.includes("name")) {
      assistantObj.content = `${assistantObj.content} ${questionsMap.name}`;
      return chats;
 }
  }
  useEffect(() => {
    if (
      QuestionIndex === 1 &&
      data?.messages?.length > 1 &&
      data?.messages?.length < 5
    ) {
      handleCreateLead();
    }
    if (
      widgetSettings.informationToCapture.length > 0 &&
      data?.messages?.length > 1 &&
      data?.messages?.length <= 7 &&
      messages?.length >= data?.messages?.length
    ) {
      console.log("chate updated",messages.length)
      updateChatDoc("", widgetSettings.widgetId ?? "", chatId, messages);
    }
  }, [QuestionIndex]);


  useEffect(() => {
    if (
      widgetSettings.informationToCapture.length > 0 &&
      data?.messages?.length > 1 &&
      data?.messages?.length <= 7 &&
      messages?.length > 0
    ) {
      if (QuestionIndex > 0) {
        console.log("userInof", userInfo)
        updateLeadInfo(
          widgetSettings.widgetId ?? "",
          chatId,
          messages,
          userInfo.email,
          userInfo.phone
        );
      }
    }
  }, [QuestionIndex, userInfo]);


  useEffect(() => {
    if (editMode && !widgetSettings.assistant) {
      return;
    }
    if (data && dataStatus === "success") {
      setChatId(data.chatId);
        let chatMessges: ChatDoc["messages"]=addNameQuestion([...data.messages]) || [...data.messages]
        setMessages(
          chatMessges.sort(
            (mes1, mes2) => mes1.createdAt.toDate() - mes2.createdAt.toDate()
          )
        );
    }
  }, [data]);

  const handleCreateLead = async () => {
    try {
      const lead = (await addLead({
        organizationName: userInfo.name || "bot",
        contactEmail: userInfo.email,
        contactName: userInfo.name,
        contactPhone:
          userInfo.phone.startsWith("+1") || userInfo.phone === ""
            ? userInfo.phone
            : `+1${userInfo.phone}`,
        apiKey: widgetSettings.userApiKey,
        createProvider: "chatbot",
      })) as { data: any };
      console.log("lead message",messages)
      const leadId = lead.data.id;
      await updateChatDoc(
        leadId,
        widgetSettings.widgetId ?? "",
        chatId,
        messages
      );
      // enqueueSnackbar("Lead added successfully!");
    } catch (error) {
      console.error("error", error);
    }
  };

  const sendMessage = useCallback(
    async (userMessage: string) => {
      try {
        setErrorMessage("");
        setStatus("online");

        if (editMode && !widgetSettings.assistant) {
          console.log("click");
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: "text",
              role: "user",
              content: userMessage,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus("typing");

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: "text",
              role: "assistant",
              content: mockResponse,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus("online");
          return;
        } else {
          const currentQuestion: string =
            widgetSettings.informationToCapture[QuestionIndex];

          // Validate and Set User Info based on User Answers
          if (data?.messages?.length > 1 && data?.messages?.length <=7) {
            setUserInfo({
              ...userInfo,
              [widgetSettings.informationToCapture[QuestionIndex]]: userMessage,
            });
            const questionKey = widgetSettings.informationToCapture[
              QuestionIndex
            ] as keyof typeof questionsMap;
            if (
              (questionKey === "email" && !isValidEmail(userMessage)) ||
              (questionKey === "phone" && !isValidPhoneNumber(userMessage))
            ) {
              setStatus("error");
              setUserInfo({ ...userInfo, [questionKey]: "" });
              setQuestionIndex(
                widgetSettings.informationToCapture.findIndex(
                  (value) => value === questionKey
                )
              );
              console.log("QuestionIndex", QuestionIndex);
              setSessionStorage(
                `chat-question-index-${widgetId}`,
                QuestionIndex
              );
              return;
            }
          }

          // Ask User Hardcoded Questions
          if (
            widgetSettings.informationToCapture.includes(currentQuestion) &&
            userInfo[currentQuestion as keyof typeof questionsMap] === "" &&
            data?.messages.length > 1 &&
            data?.messages?.length < 7 &&
            QuestionIndex + 1 < widgetSettings.informationToCapture.length
          ) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                type: "text",
                role: "user",
                content: userMessage,
                createdAt: Timestamp.now(),
              },
            ]);
            setStatus("typing");
  
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  type: "text",
                  role: "assistant",
                  content:
                    QuestionIndex + 1 < widgetSettings.informationToCapture.length
                      ? questionsMap[
                      widgetSettings.informationToCapture[
                      QuestionIndex + 1
                      ] as keyof typeof questionsMap
                      ]
                      : "Perfect! How I can help you?",
                  createdAt: Timestamp.now(),
                },
              ]);
            setStatus("online");
            setQuestionIndex((prev) => prev + 1);
            console.log("Inner QuestionIndex", QuestionIndex);
            setSessionStorage(
              `chat-question-index-${widgetId}`,
              QuestionIndex + 1
            );
            return;
          }

          // set question index to zero
          if (
            widgetSettings.informationToCapture.length > 0 &&
            QuestionIndex === widgetSettings.informationToCapture.length &&
            data?.messages.length > 1 &&
            data?.messages?.length <= 7
          ) {
            setQuestionIndex(0);
            setSessionStorage(`chat-question-index-${widgetId}`, 0);
          }

          const currentChatId = getSessionStorage(`user-widget-id-${widgetId}`);
          console.log("curentChatId",currentChatId)

          if (
            !currentChatId &&
            widgetSettings.widgetId &&
            widgetSettings.teamId
          ) {
            createChatDoc(widgetSettings.widgetId, widgetSettings.teamId)
              .then(async (chatId) => {
                setChatId(chatId);
                setSessionStorage(`user-widget-id-${widgetId}`, chatId);

                setStatus("typing");
                const docRef = doc(DB, `widgets/${widgetId}/chats`, chatId);

                  return await getMessage(
                    widgetSettings.widgetId!,
                    chatId,
                    userMessage,
                    [],
                    isPaid
                  ).catch((err) => {
                    console.error(err);
                    setStatus("error");
                    setErrorMessage(err?.message || "Something is wrong. You can repeat the request.");
                  });
                
              })
              .then(() => {
                setTimeout(() => {
                  setStatus("online");
                }, 300);
              })
              .catch((err: any) => {
                console.log("error",err)
                setErrorMessage(err?.message);
                setStatus("error");
              });
          } else {
            if (chatId && widgetSettings.widgetId) {
              console.log("senond conditon")
              setStatus("typing");
              await getMessage(
                widgetSettings.widgetId,
                chatId,
                userMessage,
                data?.messages.length ? messages : data.messages,
                isPaid
              );
              setTimeout(() => {
                setStatus("online");
              }, 300);
            } else {
                  console.log("senond conditon ne doc")
              const newChatId = await createChatDoc(
                widgetSettings.widgetId!,
                widgetSettings.ownerUid!
              );
              setChatId(newChatId);
              setSessionStorage(`user-widget-id-${widgetId}`, newChatId);
              setStatus("typing");
              await getMessage(
                widgetSettings.widgetId!,
                newChatId,
                userMessage,
                data?.messages.length ? messages : data.messages,
                isPaid
              );
              setStatus("online");
            }
          }
        }
      } catch (error: any) {
        setErrorMessage(error?.message);
        setStatus("error");
        console.error("Error sending message:", error);
      }
    },
    [messages, status, widgetSettings, userInfo, QuestionIndex, data]
  );

  const clearConversation = useCallback(async () => {
    const id = widgetId || widgetSettings.widgetId;
    const currChatId = getSessionStorage(`user-widget-id-${id}`);

    const handlResetUserInfo = () => {
      setQuestionIndex(0);
      setSessionStorage(`chat-question-index-${widgetId}`, 0);
      setUserInfo({ name: "", email: "", phone: "" });
      setMessages([]);
    };
    try {
      if (id && currChatId) {
        await clearAllConversation(id, currChatId);
        setStatus("online");
        setChatId("");
        handlResetUserInfo();
      } else {
        handlResetUserInfo();
      }
    } catch (err: any) {
      console.error(err);
      setStatus("error");
      setErrorMessage(err?.message);
    }
  }, [widgetSettings, widgetId, chatId]);

  const context = useMemo(
    () => ({
      errorMessage,
      clearConversation,
      messages,
      sendMessage,
      status,
      setStatus,
      chatOpen,
      setChatOpen,
      editMode,
      isHome,
    }),
    [chatOpen, clearConversation, messages, sendMessage, status, editMode, isHome, errorMessage,userInfo,QuestionIndex]
  );

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>;
};
