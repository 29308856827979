"use client";
import React from "react";

// ----------------------------------------------------------------------

import ThemeProvider from "../theme";
import { ChatContextProvider } from "../context";
import { withFirebaseProviders } from "../hocs/with-react-fire";
import { withSetWidgetSettings } from "../hocs/with-set-widget-settings";
import { withWidgetSettings } from "../hocs/with-settings-layout";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  editMode?: boolean;
  widgetId?: string; // needed onlu for creating a bundle
  defaultOpen?: boolean;
  isHome?: boolean;
};

export const WidgetLayout = withFirebaseProviders(
  ({ children, editMode, widgetId, defaultOpen, isHome }: Props) => {
    return (
      <ChatContextProvider
        widgetId={widgetId}
        editMode={editMode}
        defaultOpen={defaultOpen}
        isHome={isHome}
      >
        <ThemeProvider>{children}</ThemeProvider>
      </ChatContextProvider>
    );
  }
);

const WidgetLayoutFire = withFirebaseProviders(WidgetLayout);

export const WidgetLayoutWithSettings = ({ widgetId, defaultOpen, isHome, ...props }: Props) => {
  const WidgetLayoutWithAllSettings = withSetWidgetSettings(WidgetLayoutFire, widgetId!, isHome);
  const WidgetLayoutWithSettings = withWidgetSettings(WidgetLayoutWithAllSettings);

  return (
    <WidgetLayoutWithSettings
      widgetId={widgetId}
      {...props}
      defaultOpen={defaultOpen}
      isHome={isHome}
    />
  );
};
