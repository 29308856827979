import { Timestamp, collection, doc, getDoc, setDoc, updateDoc,getDocs,query } from "firebase/firestore";
import { getResponse, getResponsePRO, widgetDB } from "./firebase";
import { WidgetDoc } from "./types";
import { ChatDoc } from "../types";
import { setSessionStorage } from "../hooks/use-session-storage";

export const getWidgetById = async (widgetId: string): Promise<WidgetDoc> => {
  const docRef = doc(widgetDB, `widgets/${widgetId}`);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as WidgetDoc;
};

export const createChatDoc = async (widgetId: string, ownerUid: string): Promise<string> => {
  const newDocId = doc(collection(widgetDB, `widgets/${widgetId}/chats`)).id;

  const newChat: ChatDoc = {
    chatId: newDocId,
    createdAt: Timestamp.now(),
    messages: [],
    createdBy: "user",
    widgetId: widgetId,
    ownerUid,
  };

  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, newDocId);

  await setDoc(docRef, newChat);

  return newDocId;
};

export const getMessage = async (
  widgetId: string,
  chatId: string,
  message: string,
  messages: any[],
  isPaid?: boolean
) => {
  try {
    const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

    await setDoc(
      docRef,
      {
        messages: [
          ...messages,
          {
            type: "text",
            role: "user",
            content: message,
            createdAt: Timestamp.now(),
          },
        ],
      },
      { merge: true }
    );

    if (isPaid) {
      await getResponsePRO({
        chatId,
        widgetId,
      });
    } else {
      await getResponse({
        chatId,
        widgetId,
      });
    }
  } catch (error: any) {
    if (error?.code === "functions/deadline-exceeded") {
        throw new Error("Sorry, your request timed out.");
      
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("timeout")) {
      throw new Error("Timeout. Please try again.");
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("internal")) {
      throw new Error("Something went wrong. Please try again.");
    }
    throw error;
  }
};

export const clearAllConversation = async (widgetId: string, chatId: string) => {
  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

  setSessionStorage(`user-widget-id-${widgetId}`, "");
};
export const updateChatDoc = async (
  leadId: string,
  widgetId: string,
  chatId: string,
  messages: any[]
) => {

//Adding 2 minutes in chatbot timeline item so that , it
//comen after the lead info timeline item in lead timeline
const currentDateTime = Timestamp.now().toDate();
const adjustedDateTime = new Date(currentDateTime.getTime() + (2 * 60 * 1000)); // add 2 minutes in current time
const updatedTimestamp = Timestamp.fromDate(adjustedDateTime);
    
  const timeline = {
    created: updatedTimestamp,
    type: "chatbot",
    messages: messages,
  };
  
  if (leadId) {
    const timelineRef = doc(collection(widgetDB, "leads", leadId, "timeline"));
    await setDoc(timelineRef, timeline);
    const WidgetdocRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);
    await setDoc(
      WidgetdocRef,
      {
        timelineId: timelineRef.id,
        leadId,
        messages: messages,
      },
      { merge: true }
    );
  } else {
    const WidgetdocRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);
    await setDoc(
      WidgetdocRef,
      {
        messages: messages,
      },
      { merge: true }
    );
  }

};

export const updateLeadInfo = async (
  widgetId: string,
  chatId: string,
  messages: any[],
  email: string,
  phoneNumber: string,
) => {
 
  const ChatdocRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);
  const docSnap = await getDoc(ChatdocRef);
  const leadId = docSnap.data()?.leadId; 
  // await updateDoc(
  //   ChatdocRef,
  //   {
  //     messages,
  //     isUpdate: true,
  //   }
  // );
  if (leadId) {
    const contactsRef = query(collection(widgetDB, "leads", leadId, "contacts"));
    const contactsQuerySnapshot = await getDocs(contactsRef);
    const contactData = contactsQuerySnapshot.docs[0].data()
    const firstContactDoc = contactsQuerySnapshot.docs[0].ref;
    await setDoc(
      firstContactDoc,
      {
        email: email ? email : contactData?.email,
        phoneNumber: phoneNumber ? phoneNumber : contactData?.phoneNumber,
      },
      { merge: true }
    );
  }
 
};