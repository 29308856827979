import React from "react";

// @mui
import { Stack, Typography, IconButton, Tooltip, Box, alpha, useTheme } from "@mui/material";
// utils
import { useContext } from "react";
// @types
// components
import BadgeStatus from "../components/badge-status";
import { CustomAvatar } from "../components/custom-avatar";
import { ChatContext } from "../context";
import { bgGradient } from "../theme/css";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";
import chroma from "chroma-js";

// ----------------------------------------------------------------------

export default function ChatHeaderDetail() {
  const { setChatOpen, clearConversation } = useContext(ChatContext);
  const theme = useTheme();

  const settings = useWidgetSettingsContext();
  const { status } = useContext(ChatContext);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(2),
        bgcolor: "background.paper",
        ...bgGradient({
          direction: "0deg",
          startColor: alpha(theme.palette.primary.main, 0.8),
          endColor: alpha(
            theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
            1
          ),
        }),
        position: "relative",
        backgroundColor: "common.white",
      }}
    >
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center" width={1}>
          <CustomAvatar
            src={(settings.assistantAvatar as any)?.preview}
            sx={{
              ...((settings.assistantAvatar as any)?.preview
                ? {
                    background: "none",
                  }
                : {}),
            }}
            alt={settings.name}
            name={settings.name}
            BadgeProps={{
              badgeContent: <BadgeStatus status={status} />,
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                color:
                  chroma(theme.palette.primary.main || "white").luminance() > 0.4
                    ? "#000"
                    : "white",
                pr: 3,
              }}
            >
              {settings.name}
            </Typography>

            <Stack spacing={1} direction="row" width={1} justifyContent="space-between">
              <Box sx={{ position: "absolute", top: 5, right: 5 }}>
                <Tooltip title="Close the chat">
                  <IconButton
                    onClick={() => setChatOpen(false)}
                    sx={{
                      p: 0.5,
                    }}
                  >
                    <Iconify
                      sx={{
                        color:
                          chroma(theme.palette.primary.main || "white").luminance() > 0.4
                            ? "#000"
                            : "white",
                      }}
                      width={20}
                      icon="iconamoon:close-bold"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ position: "absolute", bottom: 5, right: 5, zIndex: 999 }}>
                <Tooltip title="Clear the conversation">
                  <IconButton
                    onClick={clearConversation}
                    sx={{
                      p: 0.5,
                    }}
                  >
                    <Iconify
                      sx={{
                        color:
                          chroma(theme.palette.primary.main || "white").luminance() > 0.4
                            ? "#000"
                            : "white",
                      }}
                      width={20}
                      icon="mdi:delete-empty-outline"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
